<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong>
          Sportycrash Gifts
        </strong>
      </div>
    </section>
    <div class="px-2">
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#1" aria-expanded="false" aria-controls="1">
          <strong>HOW DO I USE MY SPORTYCRASH KARIBU GIFT?</strong>
        </div>
        <div class="promo-content text-light collapse p-2 show" id="1">
          <!--Some html static content-->
         <p>Sportycrash Karibu Gift is a welcome gift for all new customers.</p>
         <p>The gift is used to Top-up your Bonus Account every time you Bet until the gift is fully used.</p>
         <p>To use your Karibu Gift, follow the below steps:</p>
         <ol>
             <li>
                 Register on Sportycrash.
             </li>
             <li>
                 Deposit funds into your Sportycrash account.
             </li>
             <li>
                 Place a cash bet of 10/= and above.
             </li>
         </ol>
         <p>
             After placing your bet, 10% of your Bet Amount will be instantly Topped Up into your Bonus
                Account. <br>
                EXAMPLE; If you place a bet of Ksh.1000/=, you get Ksh.100/= into your Bonus Account which
is 10% of your stake of 1,000 Bob.
<br>
Use the Bonus Money to place bets without having to deposit again.

         </p>
         <p>Terms and Conditions</p>
         <ul>
             <li>Minimum stake is Ksh.10/=</li>
             <li>To Bet with Bonus Funds place a bet of Minimum odds of 3.5.</li>
         </ul>
        <div class="text-center">
 <router-link to="/deposit" class="deposit-link">
            <button class="depo-btn depo-btn-gift text-light">Deposit To Claim KSH. 2,000/=</button>
          </router-link>
        </div>
         
        </div>
      </section>


    </div>

    <!-- <section class="social-links" style=" ">
       <div class="text-light width-available text-center">
        <strong>
          Frequently Asked Questions
        </strong>
      </div>
    </section> -->
  </div>

</template>

<script>

export default {
  name: 'Gift',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","gift");
    this.reloadProfile();

  }

}
</script>